<template>
  <v-container class="down-top-padding" fluid>
    <BaseBreadcrumb :breadcrumbs="breadcrumbs" :icon="page.icon" :title="page.title"></BaseBreadcrumb>

    <v-row>
      <v-col cols="6">
        <v-card>
          <v-img
              :src="main_image"
              max-height="220"
              contain
              class="grey darken-4"
          ></v-img>

        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          md="12"
      >
        <v-btn
            class="mr-4"
            color="primary"
            x-large
            @click="submit"
        >
          UPDATE
        </v-btn>

        <v-btn
            class="mr-4"
            color="primary"
            x-large
            @click="goBack"
        >
          BACK
        </v-btn>

      </v-col>
    </v-row>

    <v-row>

      <v-form v-model="valid">
        <v-container>
          <v-row>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="product"
                  label="DESCRIZIONE ARTICOLO"
                  required
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="product_code"
                  label="CODICE ARTICOLO"
                  required
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="amount"
                  label="GIACENZA"
                  required
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="main_category"
                  label="MAIN CATEGORY"
                  required
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="price"
                  label="PREZZO"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-row>

  </v-container>
</template>

<script>
import {db} from '@/firebase';

export default {
  name: "CS_CART_Detail",
  data: () => ({
    source: '',
    valid: false,
    product_id: '',
    product: '',
    product_code: '',
    amount: 1,
    main_category: '',
    price: '',
    main_image: '',
    current: {},
    page: {
      title: "Dettaglio Prodotto"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Dettaglio CS-CART Product",
        disabled: true
      }
    ]
  }),
  mounted() {
    if (this.$route.params.data) {

      this.current = this.$route.params.data
      this.main_image = this.current.main_pair.detailed['https_image_path']
      this.product_id = this.current.product_id
      this.product = this.current.product
      this.product_code = this.current.product_code
      this.amount = this.current.amount
      this.main_category = this.current.main_category
      this.price = this.current.price
    }
  },
  methods: {
    submit() {

      let axios = require('axios');
      let data = JSON.stringify({
        amount: this.amount,
        product: this.product,
        price: this.price}
        );


      let config = {
        method: 'put',
        url: 'https://cors-anywhere.herokuapp.com/https://www.farmania05.it/pisilli/api/products/' + this.product_id,
        headers: {
          'Authorization': 'Basic YW1taW5pc3RyYXppb25lQGZhcm1hbmlhLml0OjBCNTVDODdvMjJxbzY3bHM3SU84TzI0NzMxTTI4Yjho',
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          }.bind(this))
          .catch(function () {
            // this.showNotification(error.toLocaleString(), false)
            return
          }).finally(() => {
        this.transitPersistance();
      });

    },
    transitPersistance() {

      let _current = this.current
      _current.amount = this.amount
      _current.price = this.price
      _current.product = this.product

      db.collection('farmacie')
          .doc(this.$route.params.pharmacy_id)
          .collection('cs_cart_products')
          .doc(this.current.product_id)
          .set(_current, { merge: true }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$router.back();
      })

    },
    goBack() {
      this.$router.back();
    },
    validateObject() {

      let newPharmacy = {
        bName: this.bName,
        username: this.username,
        cod_min: this.cod_min,
        cod_pag: this.cod_pag,
        url_api: this.url_api,
        cs_api_key: this.cs_api_key,
        source: this.source
      }

      if (this.$route.params.data) {
        newPharmacy.id = this.$route.params.data.id
      }

      return  newPharmacy
    },
    resetForm() {
      this.bName = ''
      this.username = ''
      this.cod_min = ''
      this.cod_pag = ''
      this.url_api = ''
      this.cs_api_key = ''
      this.source = ''
      this.$router.back();
    }
  }

}
</script>

<style scoped>

</style>
